import { FC, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  allSupplierItemFieldsHaveValue,
  someSupplierItemFieldsHaveValue,
  selectItemSupplierDetailsField
} from 'common/components/purchasing/requisition/store/selectors-ts';
import {
  selectItemBasicField,
  selectActiveRequisitionID,
  selectCategory
} from '@/common/components/purchasing/requisition/store/selectors-ts';
import useFieldIsDisabled from '@/common/components/purchasing/requisition/hooks/useFieldIsDisabled';
import {
  bulkUpdatePurchasingRequisitionCategoryItemsAction,
  fillQuantityFromExistings,
  getPurchasingRequisitionItems,
  updatePurchasingRequisitionItem
} from '@/common/components/purchasing/requisition/store/actions';
import { selectSupplierStatus } from '@/common/components/purchasing/requisition/store/selectors-ts';
import { selectListOptionsFromStore } from '@/store/lists/selectors-ts';
import { RequistionStatusBase } from '@/common/types/purchasing';
import { SUPPLIER_FIELDS } from '../config';

import ActivationCirlce from '@/common/components/general/ActivationCirlce';
import CircledButton from '@/ts-common/components/buttons/CircledButton';
import check from '@/common/assets/svg/common/check-circle-solid.svg';
import dashCircled from '@/common/assets/svg/common/dash-circle.svg';
import circle from '@/common/assets/svg/common/circle.svg';
import Tooltip from '@/ts-common/components/general/Tooltip';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';
import permissions from '@/common/utils/permissions/constants';
import AuthCheck from '@/components/permissions/AuthCheck';

type AutoIncludeItemButtonProps = {
  isFooter?: boolean;
  isHidden?: boolean;
  supplierRequisitionID?: string;
  itemID: string;
  categoryID: number;
};
const includedInKey = 'included_in_po';

const bulkInfo = {
  set_included_in_po: 'Include all in Purchase Order',
  set_not_included_in_po: 'Exclude all from Purchase Order'
};
const activationInfo = {
  set_included_in_po: 'Include in Purchase Order',
  set_not_included_in_po: 'Exclude from Purchase Order',
  is_included_in_po: 'Included in Purchase Order',
  is_not_included_in_po: 'Excluded from Purchase Order'
};
const proposedQtyKey = SUPPLIER_FIELDS.proposed_quantity.key;
const availableQtyKey = SUPPLIER_FIELDS.available_quantity.key;

const AutoIncludeItemButton: FC<AutoIncludeItemButtonProps> = ({
  isFooter,
  isHidden,
  supplierRequisitionID,
  itemID,
  categoryID
}) => {
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const { tooltipID, avoidRender } = useTooltipID('include-btn');

  const activeId = useAppSelector(selectActiveRequisitionID);
  const category = useAppSelector(state => selectCategory(state, categoryID));

  const allSelected = useAppSelector(state =>
    isFooter && supplierRequisitionID
      ? allSupplierItemFieldsHaveValue(state, proposedQtyKey, supplierRequisitionID, categoryID)
      : false
  );
  const someSelected = useAppSelector(state =>
    isFooter && supplierRequisitionID
      ? someSupplierItemFieldsHaveValue(state, proposedQtyKey, supplierRequisitionID, categoryID)
      : false
  );

  const proposedQtyValue = useAppSelector(state =>
    !isFooter && supplierRequisitionID
      ? selectItemSupplierDetailsField(state, itemID, proposedQtyKey, supplierRequisitionID)
      : false
  );
  const availableQtyValue = useAppSelector(state =>
    !isFooter && supplierRequisitionID
      ? selectItemSupplierDetailsField(state, itemID, availableQtyKey, supplierRequisitionID)
      : false
  );

  const requisitionItemID = useAppSelector(state =>
    selectItemBasicField(state, itemID, 'requisition_item_id')
  );

  const onBulkUpdate = useCallback(
    async (excludeAll: boolean) => {
      if (!supplierRequisitionID) return;

      try {
        setIsSaving(true);

        if (excludeAll) {
          await dispatch(
            bulkUpdatePurchasingRequisitionCategoryItemsAction({
              [proposedQtyKey]: 0,
              categoryID,
              supplierRequisitionID
            })
          );
        } else {
          const params = {
            id: activeId,
            field: proposedQtyKey,
            item_type: [category.item_types],
            purchasing_category_id: categoryID
          };

          await dispatch(
            fillQuantityFromExistings({ ...params, supplierID: supplierRequisitionID })
          );
        }

        await dispatch(
          getPurchasingRequisitionItems({
            id: activeId,
            requisition_supplier_id: supplierRequisitionID
          })
        );

        setIsSaving(false);
      } catch (error) {
        setIsSaving(false);
      }
    },
    [supplierRequisitionID, dispatch, activeId, categoryID, category?.item_types]
  );

  const onSingleFieldUpdate = useCallback(
    async (value: boolean) => {
      if (!supplierRequisitionID) return;

      try {
        setIsSaving(true);

        await dispatch(
          updatePurchasingRequisitionItem({
            [proposedQtyKey]: value ? availableQtyValue : 0,
            id: requisitionItemID,
            supplierRequisitionID: supplierRequisitionID
          })
        );

        await dispatch(
          getPurchasingRequisitionItems({
            id: activeId,
            requisition_supplier_id: supplierRequisitionID
          })
        );
        setIsSaving(false);
      } catch (error) {
        setIsSaving(false);
        dispatch(
          getPurchasingRequisitionItems({
            id: activeId,
            requisition_supplier_id: supplierRequisitionID
          })
        );
      }
    },
    [dispatch, supplierRequisitionID, availableQtyValue, requisitionItemID, activeId]
  );

  return (
    <>
      <div id={tooltipID}>
        {isFooter ? (
          isHidden ? null : (
            <CircledButton
              icon={allSelected ? check : someSelected ? dashCircled : circle}
              size={16}
              svgColor={allSelected || someSelected ? 'green' : 'inactive'}
              svgStyle={{ width: 10, height: 10 }}
              disabled={isSaving}
              onClick={() => onBulkUpdate(allSelected || someSelected ? true : false)}
            />
          )
        ) : (
          <ActivationCirlce
            isActive={+proposedQtyValue ? true : false}
            disabled={isSaving || isHidden}
            onClick={() => onSingleFieldUpdate(+proposedQtyValue ? false : true)}
          />
        )}
      </div>

      {!avoidRender ? (
        <Tooltip target={tooltipID}>
          {isFooter
            ? someSelected
              ? bulkInfo[`set_not_${includedInKey}`]
              : bulkInfo[`set_${includedInKey}`]
            : isHidden
            ? proposedQtyValue
              ? activationInfo[`is_${includedInKey}`]
              : activationInfo[`is_not_${includedInKey}`]
            : proposedQtyValue
            ? activationInfo[`set_not_${includedInKey}`]
            : activationInfo[`set_${includedInKey}`]}
        </Tooltip>
      ) : null}
    </>
  );
};

type SupplierIncludedInPoButtonProps = {
  isFooter: boolean;
  supplierRequisitionID: string;
  itemID: string;
  categoryID: number;
};

const SupplierIncludedInPoButton: FC<SupplierIncludedInPoButtonProps> = ({
  isFooter,
  itemID,
  categoryID,
  supplierRequisitionID
}) => {
  const supplierStatus = useAppSelector(state =>
    supplierRequisitionID ? selectSupplierStatus(state, supplierRequisitionID) : null
  );
  const statuses = useAppSelector(
    state =>
      selectListOptionsFromStore(state, 'purchasing-requisition-statuses') as RequistionStatusBase[]
  );

  const fieldIsDisabled = useFieldIsDisabled(SUPPLIER_FIELDS.proposed_quantity.key, {
    supplierRequisitionID
  });

  const poStatus = statuses.length ? statuses.find(s => s.label === 'po') : null;

  const isHidden =
    (!!supplierStatus && !!poStatus && supplierStatus.sort_index > poStatus.sort_index) ||
    fieldIsDisabled;

  return (
    <AuthCheck
      permissions={[permissions.office.purchasing.requisitions.items.edit_proposed_quantity]}
    >
      {isFooter ? (
        <div className="ms-auto min-w-24 max-w-24 position-relative d-flex align-items-center justify-content-center">
          <AutoIncludeItemButton
            itemID={itemID}
            categoryID={categoryID}
            supplierRequisitionID={supplierRequisitionID}
            isHidden={isHidden}
            isFooter
          />
        </div>
      ) : (
        <AutoIncludeItemButton
          isHidden={isHidden}
          itemID={itemID}
          categoryID={categoryID}
          supplierRequisitionID={supplierRequisitionID}
        />
      )}
    </AuthCheck>
  );
};

export default SupplierIncludedInPoButton;
